<template>
    <div class="wrapper-child">
        <div class="container margin-top-30">

			<div class="row">
				<div class="col-md:col-8 padding-bottom-30 col-sm-8">

					<div class="layout_1 margin-bottom-30">
						<div class="row">
							<div class="col-md:col-12">
								<h3 class="heading-1"><span></span></h3>
								<Skeleton v-if="skeleton.slideshow" width="100%" height="300px" class="mb-2"></Skeleton>
								<div v-else class="post-carousel-wrap">
									<div class="post-carousel">
										<div v-for="(item, i) in slideshow" :key="i" class="layout_1--item">
											<Image :src="`${assets_folder}/slideshow/${item.image}`" imageClass="img-responsive" alt="" preview>
												<template #indicator>
													<div class="layout-detail padding-25">
														<h4>{{ item.title }}</h4>
														<p>{{ item.description }}</p>
													</div>
												</template>
											</Image>
										</div>
										<!-- <div class="layout_1--item">
											<a href="#">
												<span class="badge text-uppercase badge-overlay badge-tech">Tech</span>
												<div class="overlay"></div>
												<img src="/assets/images/home/04/2.jpg" class="img-responsive" alt=""/>
												<div class="layout-detail padding-25">
													<h4>Pokemon Go Creator Says It's Not Reading</h4>
													<p>Libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit...</p>
													<div class="meta"><span class="author">by Mahita G.</span><span class="date">Sep. 27, 2016</span><span class="comments">3</span></div>
												</div>
											</a>
										</div> -->
									</div>
									<a class="prev"><i class="pi pi-chevron-left" style="fontSize: 3rem"></i></a>
									<a class="next"><i class="pi pi-chevron-right" style="fontSize: 3rem"></i></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col-md:col-12">
						<h3 class="heading-1"><span>Berita</span></h3>
						<div v-if="skeleton.slideshow" class="grid">
							<div v-for="i in 3" :key="i" class="sm:col-4">
								<Skeleton width="100%" height="150px" class="mb-2"></Skeleton>
								<Skeleton height="2rem" width="100%" class="mb-2" borderRadius="16px"></Skeleton>
							</div>
						</div>
						<div v-else class="grid">
							<div v-for="(post, i) in posts" :key="i" class="sm:col-4">
								<div class="layout_2--item">
									<div class="thumb">
										<router-link :to="`/p/${post.id}/${post.slug}`"><img v-if="post.image" :src="`${assets_folder}/posts/${post.image}`" class="img-responsive" alt=""/></router-link>
									</div>
									<!-- <span class="cat">Business</span> -->
									<h4><router-link :to="`/p/${post.id}/${post.slug}`">{{ post.title }}</router-link></h4>
									<div class="meta">
										<!-- <span class="author">by Rana F.</span> -->
										<span class="date">{{ $filter_date(post.created_at) }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
				
				<!-- SIDEBAR -->
				<Sidebar :sidebar="sidebar" />
				<!-- // SIDEBAR -->
			</div>
		</div>
    </div>
</template>

<script>
import Sidebar from '@/landing/_Sidebar.vue'
export default {
	components: {
		Sidebar,
	},
	props: ['settings', 'sidebar'],
	data() {
		return {
			api: '/api/landing',
			assets_folder: process.env.VUE_APP_ASSETS_FOLDER,
			slideshow: [],
			posts: [],
			skeleton: {
				slideshow: true,
				posts: true,
			}
		}
	},
	watch: {
		settings() {
			this.$e.emit('updateTitle', this.settings.general.site_name);
		}
	},
	methods: {
		loadSlideshow(){
			this.$Progress.start();
			this.$httpAuth.get(`${this.api}/load/slideshow`)
			.then((response) => {
				this.$Progress.finish();
				this.skeleton.slideshow = false;
				this.slideshow = response.data;
				require('@/assets/js/slick.min.js');
				setTimeout(() => {
					require('@/assets/js/main.js');
				}, 500)
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		getPosts(){
			this.$Progress.start();
			this.$httpAuth.get(`${this.api}/posts`, {
				params: {
					per_page: 3,
					page: 1,
					// category: 1
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.skeleton.posts = false;
				this.posts = response.data.data;
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
	},
	created(){
		this.$e.emit('updateTitle', this.settings.general.site_name);
	},
	mounted(){
		this.loadSlideshow();
		this.getPosts();
	}
}
</script>