<template>
    <aside class="col-md:col-4 col-sm-4">
        <div class="side-widget margin-bottom-50">
            <h3 class="heading-1"><span>Kegiatan</span></h3>
            <ul v-for="(kegiatan, i) in sidebar.kegiatan" :key="i" class="side-posts">
                <li><span>{{ $filter_date(kegiatan.tanggal) }}</span> <router-link :to="`/k/${kegiatan.id}/${kegiatan.slug}`">{{ kegiatan.nama }}</router-link></li>
            </ul>
        </div>

        <div class="side-widget margin-bottom-60">
            <h3 class="heading-1"><span>Kategori</span></h3>
            <ul v-for="(category, i) in sidebar.categories" :key="i" class="tags">
						<li><router-link :to="`/c/${category.id}/${category.slug}`">{{ category.name }}</router-link></li>
					</ul>
            <div class="clearfix"></div>
        </div>
    </aside>
</template>

<script>
export default {
	props: ['sidebar'],
	data() {
		return {
			
		}
	},
	computed: {
		
	},
	methods: {
		
	},
	created(){
		
	},
	mounted() {
		
	},
}
</script>